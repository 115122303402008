import { Resume } from '../types';

const data: Resume = {
  candidate: {
    name: 'Stephen Roth',
    tagline:
      "Building modern applications that your customers will love to use",
    avatar: 'images/stephen-roth-avatar.png',
    location: 'Greater St. Louis Area',
    website: { name: 'designbystephen.com', url: 'http://designbystephen.com' },
    code: {
      name: 'github.com/designbystephen',
      url: 'http://github.com/designbystephen',
    },
    profile: {
      name: 'in/designbystephen',
      url: 'https://www.linkedin.com/in/designbystephen',
    },
  },

  experience: [
    {
      organization: 'eSUB Construction Software',
      title: 'Principal Software Engineer',
      startDate: '04/2021',
      endDate: '05/2023',
      details: [
        'Launched a new Service (SaaS) platform',
        'Architected modern, secure, and scalable applications in the cloud as a full-stack engineer',
        'Developed modern, performant, and accessible web applications using React',
        'Innovated software engineering practices, principles, and patterns',
        'Mentored junior and senior full-stack software engineers',
        'Delivered quality applications via test-driven development (TDD), continuous integration (CI), continuous deployment (CD), and automated testing',
        'Adapted interface designs from collaborative design tools (Figma, Zeplin) into application features',
        'Refined requirements and communicated software solutions with non-technical stakeholders in an Agile environment',
        'Delivered features as part of an Agile Scrum team',
      ],
      tags: [
        'React',
        'Redux',
        'Typescript',
        'GraphQL',
        'Jest',
        'Cypress',
        'Storybook',
        'Material UI (MUI)',
        'JSS',
        'Yum',
        'Real User Monitoring (RUM)',
        'Launch Darkly',
        'Node.js',
        'Serverless',
        'AWS S3',
        'AWS Lambda',
        'AWS Cognito',
        'AWS AppSync',
        'AWS CloudFront',
        'AWS DynamoDB',
      ],
    },
    {
      organization: 'Change Healthcare',
      title: 'Lead Software Engineer',
      startDate: '12/2017',
      endDate: '04/2021',
      details: [
        'Launched new on-premise applications and services',
        'Architected modern, secure, and scalable applications in the cloud as a full-stack engineer',
        'Developed modern, performant, and accessible web applications using React',
        'Led and mentored junior and senior full-stack software engineers',
        'Innovated software engineering practices, principles, and patterns',
        'Delivered quality applications via test-driven development (TDD), continuous integration (CI), continuous deployment (CD), and automated testing',
        'Adapted interface designs from collaborative design tools (Figma) into application features',
        'Managed Agile projects and scrum teams within a scaled agile environment (SAFe)',
        'Refined requirements and communicated software solutions with non-technical stakeholders in an Agile environment',
        'Delivered features as part of an Agile Scrum team',
      ],
      tags: [
        'React',
        'Redux',
        'Typescript',
        'GraphQL',
        'Jest',
        'Cucumber',
        'Node.js',
        'Swagger',
        'Open API Specification',
        'REST',
        'AWS S3',
        'AWS Lambda',
        'AWS Cognito',
        'AWS CloudFront',
        'AWS DocumentDB',
        'MongoDB',
        'Jenkins',
        'Docker',
      ],
    },

    {
      organization: 'NJVC',
      title: 'Senior Software Engineer',
      startDate: '9/2015',
      endDate: '12/2017',
      details: [
        'Completed development on a new Software as a Service (SaaS) product',
        'Designed and developed modern, performant, and accessible web applications using React and Ember.js',
        'Designed and developed native mobile applications using Cordova',
        'Led, mentored, and trained engineering teams across multiple projects',
        'Innovated UI development practices, including migration to React frameworks',
        'Improved application quality with unit testing practices and test-driven development (TDD)',
        'Refined requirements and communicated software solutions with non-technical stakeholders in a scaled Agile (SAFe) environment',
      ],
      tags: [
        'React',
        'Redux',
        'Ember.js',
        'Node.js',
        'Express',
        'Webpack',
        'Jest',
        'OpenLayers',
        'ArcGIS',
        '.NET Core',
        'Cordova',
        'Android',
        'iOS',
        'OAuth',
        'REST',
        'HAL',
        'Bamboo',
      ],
    },

    {
      organization: 'Various Companies in the Healthcare Industry',
      title: 'Senior Consultant',
      startDate: '3/2010',
      endDate: '9/2015',
      details: [
        'Engineered, developed, and designed accessible web applications used for Computerized Physician Order Entry (CPOE)',
        'Innovated application capabilities in the industry by integrating clinical decision support modules',
        'Integrated with order sets and patient data from various electronic record systems (EHRs)',
        'Led, mentored, and trained on-site developers and staff',
        'Authored Software, API, and training documentation',
      ],
      tags: [
        'Bootstrap',
        'jQuery',
        'Prototype',
        'JavaScript',
        'CSS',
        'HTML',
        'PHP',
        'Python',
        'MySQL',
        'Oracle',
        'API',
        'VGR',
        'C#.NET',
        'VB.NET',
        'ASP.NET',
      ],
    },

    {
      organization: 'Decatur Memorial Hospital',
      title: 'Webmaster',
      startDate: '2008',
      endDate: '2010',
      details: [
        'Award-winning development',
        'Engineered, developed, and designed accessible web applications used for Computerized Physician Order Entry (CPOE)',
        'Engineered, developed, and designed multiple business solutions including surveys, issue trackers, message boards, and email marketing campaigns',
        'Developed and maintained internal and external websites',
        'Innovated Computerized Physician Order Entry (CPOE) applications by integrating clinical decision support modules',
        'Integrated with order sets and patient data from various electronic record systems (EHRs)',
      ],
      tags: [
        'HTML',
        'JavaScript',
        'CSS',
        'C#.NET',
        'VB.NET',
        'ASP.NET',
        'WordPress',
        'MSSQL',
        'Oracle',
        'VGR',
      ],
    },
  ],

  education: [
    {
      institution: 'DeVry University',
      certifiedOn: '10/2008',
      certification: 'Bachelor of Science, Computer Information Systems (CIS)',
    },
  ],

  technicalSkills: [
    'React',
    'Redux',
    'Typescript',
    'Responsive Design',
    'Node.js',
    'GraphQL',
    'REST',
    'Serverless',
    'SaaS',
    'Amazon Web Services (AWS)',
    'AWS S3',
    'AWS Cognito',
    'AWS Lambda',
    'AWS CloudFront',
    'AWS DocumentDB',
    'AWS DynamoDB',
    'HTML',
    'CSS',
    'SASS',
    'LESS',
    'JavaScript',
    'Git',
    'Pipeline as Code',
    'Infrastructure as Code',
    'Software Documentation',
  ],

  personalSkills: [
    'Creativity',
    'Leadership',
    'Mentoring',
    'Team Building',
    'Teamwork',
    'Communication',
    'Problem-Solving',
    'Critical Thinking',
    'Decision Making',
  ],
};

export default data;
